:root {
    --primary-color: #FFBA75;
    --lightest-gray: #fcfcfc;
    --lighter-gray: #F1F2F2;
    --light-gray: #e2e2e2;
    --medium-gray: #5A5A5A;
    --dark-gray: #231F20;
    --darker-gray: #0A0A0A;
    --background-salmon: #FEF5EF;
    --fg-green: #0EDD89;
    --fg-red: #ED1C24;
    --cat-bg-favorable: #C2FFDB;
    --cat-bg-unfavorable: #FFC7CB;
    --cat-bg-unfavorable-strong: #f79195;
    --cat-bg-unfavorable-weak: #fdf7f7;
    --cat-bg-neutral: #F9F3AF;
    --cat-bg-neutral-strong: #edd05a;
    --cat-bg-neutral-weak: #f9f7e6;
    --cat-bg-none: #F1F2F2;
    --cat-fg-favorable: #0EDD89;
    --cat-fg-unfavorable: #ED1C24;
    --cat-fg-neutral: #BCBEC0;
}

@font-face {
    font-family: "Graphik";
    src: local("Graphik"), url(./fonts/graphik/Graphik-Regular.otf) format("opentype");
    font-weight: normal;
    font-style: normal;
}

body {
    color: var(--dark-gray);
    font-family: Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif;
    max-width: 800px;
    margin: auto;
}

.section {
    padding: 0.2em 3em;
}

.section.engagement {
    display: grid;
}

.section.member-cards {
    break-after: always;
    /* display: grid; */
    display: block;
    gap: 0.4em;
}

.section.member-cards .section-header:first-child {
    margin-bottom: 0;
}


.section.comments {
    break-after: always;
}

@media print {

    .section {
        break-inside: avoid;
    }

    .dont-break {
        break-inside: avoid;
    }
}


.section-header .title {
    font-size: 1.3em;
}

.report-header {
    font-size: 0.8em;
}

.section-header {
    margin: 1.5em 0 0.5em 0;
}

.section-header .badge {
    font-size: 0.6em;
    border-radius: 0.3em;
    line-height: 1.5em;
    padding: 0.3em 1em;
    margin: 0.5em 0.5em 0 0.5em;
    float: right;
}

.section-header .badge.unfavorable {
    background-color: var(--cat-bg-unfavorable);
}

.section-header .badge.neutral {
    background-color: var(--cat-bg-neutral);
}

.section-header .badge.favorable {
    background-color: var(--cat-bg-favorable);
}


.button {
    padding: 0.7em 3.4em;
    font-size: 1em;
    text-decoration: none;
    border-radius: 0.4em;
}

.primary.button {
    background-color: var(--primary-color);
    color: var(--darker-gray);
    font-weight: 900;
}

/*
.engagement div:after {
    content: "-";
    color: transparent;
    display: block;
    line-height: 0.5em;
} */


/* Engagement metric column */


.employee-label {
    margin-top: 1.2em;
}

div.engagement-metric {
    text-align: left;
}

.engagement-metric-title {
    font-size: 0.9em;
}

.engagement-metric-subtitle {
    display: block;
    font-style: italic;
    color: var(--medium-gray);
    padding-top: 0.3em;
    font-size: 0.6em;
}


div.engagement-distribution {
    box-sizing: border-box;
    text-align: left;
    display: flex;
    justify-content: stretch;
    overflow: hidden;
    border-radius: 0.3em;
    /* outline: 1px solid var(--light-gray); */
    /* box-shadow: inset 0px 0px 0px 1px #f00; */
}


div.engagement-distribution.engagement-header {
    outline: none;
    text-align: center;
    display: inline-block;
}

.engagement-distribution-value {
    flex-grow: 1;
    display: inline-block;
    font-size: 0.7em;
    vertical-align: middle;
    text-indent: 0.3em;
    line-height: 2em;
}

.engagement-distribution-no-value {
    display: inline-block;
    font-size: 0.6em;
    font-style: italic;
    line-height: 2em;
}

.engagement-distribution-value.unfavorable {
    background-color: var(--cat-bg-unfavorable);
}

.engagement-distribution-value.neutral {
    background-color: var(--cat-bg-neutral);
}

.engagement-distribution-value.favorable {
    background-color: var(--cat-bg-favorable);
}

div.engagement-trailing-score {
    text-align: center;

}

.engagement-trailing-score-column>.engagement-column {
    background-color: var(--background-salmon);
    border-radius: 7px;
    margin: 0 2em;
    padding: 0 0.5em;
    display: grid;
    grid-auto-flow: column;
}

div.engagement-header {
    color: var(--medium-gray);
    margin-bottom: 2em;
    height: fit-content;
    font-size: 0.7em;
}

.trailing-score.score-value.none {
    background-color: white;
}


.engagement-trailing-score-column>.engagement-column-double {
    display: grid;
    grid-template-rows: 1em repeat(7, 3.5em);
    align-items: center;
    background-color: var(--background-salmon);
    border-radius: 7px;
    margin: -1em 0 0 0.5em;
    grid-auto-flow: column;
    padding: 0 0.5em;
    grid-column-gap: 0.2em;
}



.trailingScoreColumn {
    display: contents;
}


.trailingScoreSubTitle {
    z-index: 0;
    color: var(--medium-gray);
    font-size: 0.7em;
    text-align: center;
    padding-top: 1em;
}


/* div.highlights {
    max-height: 8em;
    height: 9em;
} */

/* .highlights-section-header {
    display: flex;
    flex-direction: column;
} */

.highlights-team-header {
    margin-top: 3em;
    height: 1.5em;
    font-style: italic;
    font-size: 0.8em;
    color: var(--medium-gray);
    opacity: 0.7;
}

.highlights-table-body {
    display: grid;
    gap: 0.5em;
    grid-auto-rows: minmax(32px, auto);
    border-radius: 6px;
    /* flex-flow: column nowrap; */
    /* grid-template-rows: max-content; */
    /* grid-auto-rows: min-content; */
    /* grid-row: auto; */
    /* margin-top: 2em; */
    /* border: 1px solid black; */
    /* background-color: var(--lightest-gray); */
    /* border: 1px solid var(--light-gray); */
    /* padding: 1em; */
}

div.highlights-member {
    vertical-align: bottom;
    text-align: left;
    width: 30%;
}

div.highlights-member.turnover {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cat-bg-unfavorable);
    background-color: var(--cat-bg-unfavorable-weak);
    padding: 0.1em 0.6em;
    align-items: flex-start;
    height: fit-content;
    border-radius: 6px;
    width: inherit;
}

div.highlights-member.turnover.medium {
    border: 1px solid var(--cat-bg-neutral-strong);
    background-color: var(--cat-bg-neutral-weak);
}

div>div>div.highlights-member {
    font-size: 0.7em;
    height: 1em;
}

span.highlights-member.turnover-risk {
    font-size: 0.8em;
    font-weight: 900;
}


.highlights-table-header {
    display: grid;
    grid-template-columns: 1fr 3em 6.4em 3em 3em 3em 3em 3em 3em 3em;
    height: 2.5em;
    grid-gap: 0.5em;
    margin: 4em 0 1.5em 0;
}

.highlights-table-row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3em 6.4em 3em 3em 3em 3em 3em 3em 3em;
    grid-gap: 0.5em;
}

.highlights-table-row>div {
    width: fit-content;
    display: flex;
    align-items: center;
}

.highlights-heading-container {
    transform: translateY(-1.2em);
}


.highlights-heading {
    transform: rotate(-50deg) translate(0, 0);
    position: absolute;
    width: 8em;
    text-align: left;
    vertical-align: middle;
    bottom: 0;
    color: #A7A9AC;
    font-size: 0.7em;
}

.score {
    text-align: center;
}

.score-with-change {
    min-width: 6.4em;
}

.score-value {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 0.7em;
    width: 3.6em;
    line-height: 2em;
    border-radius: 0.3em;
    font-weight: 900;
}

.score-value.unfavorable {
    background-color: var(--cat-bg-unfavorable);
}

.score-value.neutral {
    background-color: var(--cat-bg-neutral);
    /* border: 2px solid var(--cat-bg-neutral-strong); */
}

.score-value.favorable {
    background-color: var(--cat-bg-favorable);
}

.score-value.none {
    background-color: var(--cat-bg-none);
}

.score-change {
    display: inline-block;
    /* vertical-align: middle; */
    /* line-height: 2em; */
    text-align: right;
    width: 3em;
    font-size: 13px;
    margin: 0 0.7em 0 0.7em;
}

.score-change:before {
    float: left;
}

.score-change.unfavorable {
    color: var(--cat-fg-unfavorable);
}

.score-change.unfavorable:before {
    content: url(./images/change-unfavorable.svg);
}

.score-change.neutral {
    color: var(--cat-fg-neutral);
}

.score-change.neutral:before {
    content: url(./images/change-neutral.svg);
}

.score-change.favorable {
    color: var(--cat-fg-favorable);
}

.score-change.none {
    display: none;
}

.score-change.favorable:before {
    content: url(./images/change-favorable.svg);
}


div.data {
    width: 100%;
    display: grid;
}

.engagement>div.data {
    grid-template-columns: 32% 1fr 35% 1fr;
    column-gap: 0.2em;
}

.engagement>div.data>div {
    display: grid;
    grid-template-rows: 2.1em 1fr;
}


div.engagement-column {
    display: grid;
    grid-template-rows: repeat(7, 3.5em);
    align-items: center;
}

.circle {
    transform: rotate(-90deg);
    transition: all 1s ease-in-out;
    /* SVG */
    fill: none;
    stroke: red;
    stroke-width: 8;
    stroke-linecap: round;
    stroke-dasharray: 0 999;
}

.summary {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 2%;
    background-color: var(--background-salmon);
    padding-top: 0;
    padding-bottom: 1em;
}

.summary-content {
    margin-top: 1em;
}

.summary-box {
    background-color: white;
    border-radius: 1em;
    padding: 0.5em 1em;
    display: grid;
}

.summary-header {
    color: var(--medium-gray);
    font-size: 0.7em;
}

.summary.summary-participation {
    width: 25%;
}

.summary.summary-score {
    width: 25%;
}

.summary-score-value {
    text-align: center;
    font-size: 4em;
    padding: 0.2em 0;
    width: 100%;
}

div.summary-content>.summary-disabled {
    font-size: 0.8em;
    font-style: italic;
    color: var(--medium-gray);
    opacity: 0.7;
}

.summary-score-change {
    margin: auto;
    width: 100%;
    text-align: center;
}

.summary-score-change>span {
    font-size: 0.9em;
}

.report-header>div {
    display: grid;
    grid-template: auto auto / auto auto;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    justify-items: stretch;
    grid-gap: 1em;
    /* margin: 0.5em 0; */
}

span.report-title {
    font-weight: 600;
}

.report-header.section {
    background-color: var(--background-salmon);
}

.circful-logo {
    width: 4em;
    height: auto;
    content: url(./images/circful-logo.svg);
    justify-self: right;
}

.comment {
    padding-top: 1em;

}

.comment-header {
    background-color: var(--lighter-gray);
    padding: 0.5em 1em;
    border-radius: 7px;
    margin-top: 1em;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
}

.comment-question {
    color: var(--medium-gray);
    font-size: 0.8em;
    /* max-width: 70%; */
}

.comment-metric {
    color: var(--dark-gray);
    font-size: 1em;
}

.comment-content {
    border: 1px solid var(--lighter-gray);
    padding: 0.5em 1em 2em 1em;
    margin-top: 1em;
    border-radius: 7px;
    display: flex;
}

.comment-content>span {
    color: var(--medium-gray);
    font-size: 0.8em;
}

.comment-employee {
    display: flex;
    flex-flow: column;
    min-width: 13em;
    max-width: 13em;
    width: fit-content;
}

.comment-employee-name {
    color: var(--dark-gray);
    font-size: 1em;
}

.comment-employee-role {
    color: var(--medium-gray);
    font-size: 0.8em;
}

.question {
    background-color: var(--background-salmon);
    /* border: 1px solid #eeddd2; */
    padding: 0.2em 1em;
    margin-top: 0.4em;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
}

.question-name {
    font-size: 0.7em;
    font-weight: 600;
    color: var(--dark-gray);
}

.question-content {
    font-size: 0.7em;
    color: var(--medium-gray);
}

.leadership-tip.container {
    background-color: var(--background-salmon);
    padding: 1em 1.5em 1em 1.5em;
    height: fit-content;
    border-radius: 7px;
    display: flex;
    flex-flow: column;
}

.leadership-tip.button {
    margin: 0.5em auto 0 auto;
}

.leadership-tip.content {}

.leadership-tip.illustration {
    /* content: url(./images/growth-curve-amico.png); */
    width: 20em;
    float: right;
}

.leadership-tip.metric {
    background-color: white;
    width: fit-content;
    padding: 0.3em;
    border-radius: 7px;
    font-size: 1em;
    color: var(--medium-gray);
    margin-bottom: 0.6em;
}

.leadership-tip.title {
    margin-bottom: 1em;
}

.leadership-tip.title>span {
    font-size: 1.2em;
    color: var(--darker-gray);
}

.leadership-tip.text {
    font-size: 0.9em;
    color: var(--dark-gray);
}

.leadership-tip.text a {
    color: orange;
}

.action-steps-header {
    background-color: var(--background-salmon);
    border-radius: 7px;
    padding: 1em;
}

.action-steps-header span {
    font-size: 0.9em;
}

.action-steps-header-employee {
    font-style: italic;
}

.action-steps.container>div {
    border: 1px solid var(--lighter-gray);
    margin: 1em 0 0 0;
    border-radius: 7px;
    font-size: 0.8em;
    padding: 1em;
    display: flex;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.action-steps.container>div>p {
    margin: 0;
}

.action-square {
    height: 0.9em;
    width: 0.9em;
    min-width: 0.9em;
    border-radius: 2px;
    border: 1px solid var(--medium-gray);
    margin-right: 1em;
}

.scores-faq.container {
    background-color: var(--background-salmon);
    padding: 1.5em 1em;
    margin-top: 2em;
    border-radius: 7px;
}

.scores-faq.container>p {
    font-size: 0.8em;
    margin: 0 0 1em 0;
}

/* member cards */

.member-cards.container {
    /* display: flex;
    gap: 1em; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    /* margin-top: 1em; */
    /* row-gap: 0.9em; */
    /* margin-top: 1.4em; */
}

.member-cards.container::after {
    content: "";
    display: block;
    height: 200px;
    margin-bottom: -200px;
}

.member-cards.box {
    border: 1px solid var(--light-gray);
    border-radius: 12px;
    padding: 1em;
    margin-top: 0.8em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.member-cards-role {
    /* background-color: var(--medium-gray); */
    /* opacity: 0.4; */
    border-radius: 5px;
    width: fit-content;
    color: var(--medium-gray);
    font-size: 0.6em;
    /* margin-top: 0.4em; */
    /* padding: 0.2em 0.5em; */
}

.member-cards.name-section {
    display: grid;
    /* grid-column-gap: 0.2m; */
    /* align-items: center; */
    gap: 0.3em;
    height: fit-content;
    /* height: 100%; */
}

.member-cards.turnover-risk {
    /* display: grid;
    grid-column: 1fr 1fr;
    grid-row: 1fr;
    grid-row-gap: 0.2em; */
    /* justify-content: space-between; */
    display: flex;
    height: fit-content;
    width: fit-content;
    gap: 0.4em;
    text-align: center;
    border: 1px solid var(--cat-bg-unfavorable);
    background-color: var(--cat-bg-unfavorable-weak);
    padding: 0.3em 0.6em;
    border-radius: 8px;
    margin-bottom: 0.6em;
    align-items: center;
}

.member-cards.turnover-risk.high {
    border: 1px solid var(--cat-bg-unfavorable-strong);
}

.member-cards.turnover-risk.medium {
    border: 1px solid var(--cat-bg-neutral-strong);
    background-color: var(--cat-bg-neutral-weak);
}


/* .member-cards.turnover-title-container {
    display: flex;
    align-items: center;
    gap: 0.2em;
} */


.member-cards.turnover-icon {
    font-size: 0.8em;

}

/* .member-cards.turnover-risk-title {
    font-size: 0.8em;
} */

.member-cards.turnover-risk-label {
    font-size: 0.8em;
}

.member-cards-name {
    font-size: 1em;
    color: black;
}

.member-cards-header-section {
    display: grid;
    margin: auto 0;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 0.3em;
    /* margin-bottom: 0.8em; */
    align-items: start;
}

.member-cards-missed-survey {
    text-align: left;
    font-size: 0.6em;
    margin-bottom: 0.4em;
    color: var(--dark-gray);
    /* opacity: 0.8; */
}

/* .member-cards-missed-survey:before {
    content: url(./images/warning.svg);
    vertical-align: middle;
} */

.member-cards-results-section {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto;
    align-items: center;
    margin: 0;
}

.member-cards.previous-scores {
    grid-template-columns: repeat(5, 1fr);
    display: grid;
    align-items: end;
    column-gap: 1em;
    text-align: center;
    font-size: 0.8em;
}

.dot-spacer {
    color: var(--medium-gray);
    opacity: 0.7;
    font-size: 0.8em;
    padding-bottom: 50%;
}

.member-cards.name-header {
    display: none;
    border-width: 1px;
    border-style: solid;
    width: 3em;
    height: 3em;
    margin: 0 0 0 auto;
    border-radius: 100%;

}

.member-cards.main-score {
    /* margin: 1em 0; */
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: flex-end;
    align-items: center;
    gap: 0.4em;
}

.member-cards.main-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;


}

.member-cards.main-score-container.favorable {
    border: 1px solid #0EDD89;

}

.member-cards.main-score-value {
    font-weight: 900;
    font-size: 1em;
    width: 2em;
    height: 2em;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.member-cards.main-score-change {
    font-size: 0.7em;
}

.member-cards.main-score-label {
    font-weight: 900;
    font-size: 1em;
}


.member-cards.main-score-change.unfavorable:before {
    content: url(./images/change-unfavorable.svg);
}

.member-cards.main-score-change.neutral:before {
    content: url(./images/change-neutral.svg);
}

.member-cards.main-score-change.favorable:before {
    content: url(./images/change-favorable.svg);
}


/* .member-cards.name-header {
    border-width: 2px;
    border-style: solid;
    width: 2em;
    height: 2em;
    margin: 0 0 0 auto;
    border-radius: 100%;
    display: flex;

} */

.member-cards.main-score-value.unfavorable {
    color: var(--cat-bg-unfavorable-strong);
    border: 1px solid var(--cat-bg-unfavorable-strong);
}

.member-cards.main-score-value.neutral {
    color: var(--cat-bg-neutral-strong);
    border: 1px solid var(--cat-bg-neutral-strong);
}

.member-cards.main-score-value.favorable {
    color: var(--cat-fg-favorable);
    border: 1px solid var(--cat-fg-favorable);
}

.member-cards.main-score-value.disabled {
    color: var(--cat-bg-none);
    border: 1px solid var(--cat-bg-none);
}

.member-cards.main-score-label.unfavorable {
    color: var(--cat-bg-unfavorable-strong);
}

.member-cards.main-score-label.neutral {
    color: var(--cat-bg-neutral-strong);
}

.member-cards.main-score-label.favorable {
    color: var(--cat-fg-favorable);
}

.member-cards.main-score-label.disabled {
    color: var(--cat-fg-neutral);
}



.member-cards.name-header.unfavorable {
    border-color: var(--cat-bg-unfavorable-strong);
}

.member-cards.name-header.neutral {
    border-color: var(--cat-bg-neutral-strong);
}

.member-cards.name-header.favorable {
    border-color: var(--cat-fg-favorable);
}

.member-cards.name-header.disabled {
    border-color: var(--cat-bg-none);
}





.member-cards.name-header>span {
    text-align: center;
    font-size: 0.9em;
    margin: auto;
    vertical-align: middle;
}

.member-cards.previous-scores-header {
    display: flex;
    margin-bottom: 0.2em;
    font-size: 0.7em;
    color: var(--dark-gray);
    text-align: left;
    /* opacity: 0.8; */
    justify-content: space-between;
}


.member-cards.previous-scores-header>span {
    width: fit-content;
}

div.member-cards.break-row {
    margin: 0.3em 0;
    height: 0;
    width: auto;
    border-bottom: 1px solid var(--light-gray);
}


.member-cards-metrics-section {
    font-size: 0.7em;
    text-align: left;
    color: var(--dark-gray);
    /* opacity: 0.5; */
}

.member-cards-metrics-container {
    display: grid;
    gap: 0.6em;
}

.member-cards-metrics-values {
    font-size: 1.1em;
    color: var(--dark-gray);
    display: flex;
    justify-content: space-between;
    background-color: var(--lighter-gray);
    border: 1px solid var(--light-gray);
    padding: 1em 0.6em;
    border-radius: 8px;
    margin-top: 0.4em;
    /* margin: 0.5em 0 1em 0; */
}




/* .needs-improvement-metric:before {
    content: url(./images/hammer.svg);
    vertical-align: middle;
    margin-right: 0.5em;
} */

/* .doing-great-metric:before {
    content: url(./images/star.svg);
    vertical-align: middle;
    margin-right: 0.5em;
}
 */

.box.disabled>div>div {
    opacity: 0.5;
}


.member-results-chart {
    /* height: 80px; */
    height: 100%;
}


.company-logo {
    max-height: 2em;
    justify-self: left;
}


.disclaimer-weeks {
    font-size: 0.7em;
    color: var(--medium-gray);
    opacity: 0.8;
    text-align: right;
    width: 40%;
    font-style: italic;
}

.disclaimer-weeks-container {
    display: flex;
    flex-direction: row-reverse;
    margin: 0.5em 0 -2em 0;
}


.action-content>ol {
    margin: 0;
}

/* Turnover Disclaimer */

.turnover-disclaimer.container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.8em;
    margin: 2em 0;
    border-radius: 8px;
    background-color: var(--cat-bg-unfavorable-weak);
    border: 1px solid var(--cat-bg-unfavorable);
    /* background-color: var(--background-salmon); */
    /* border: 1px solid #fadac4; */
}

.turnover-disclaimer.container>p {
    font-size: 0.8em;
    margin: 0;
}


.turnover-disclaimer.title {
    font-weight: 900;
}
